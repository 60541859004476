import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LangConstant } from "const";
import { Sidebar } from "./components";

const MainLayout = ({ children }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  return (
    <Box className={classes.layoutContainer}>
      <Box className={classes.sidebarGrid}>
        <Sidebar />
      </Box>
      <Box className={classes.mainGrid}>
        <Box className={classes.mainContainer}>
          <Box className={classes.childrenContainer}>{children}</Box>
        </Box>
        <Typography className={clsx(classes.footer, "regular-sm-txt")}>
          {getLabel(LangConstant.TXT_APP_COPYRIGHT)}
        </Typography>
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;

const useStyles = makeStyles(theme => ({
  layoutContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  sidebarGrid: {
    width: "300px",
    flexShrink: 0,
  },

  mainGrid: {
    width: "100%",
    height: "100%",
  },

  mainContainer: {
    height: "calc(100vh - 40px)",
    width: "calc(100vw - 300px)",
    backgroundColor: "#f1f1f1",
    overflow: "auto",
  },

  childrenContainer: {
    padding: "40px 25px",
    height: "100%",
    width: "100%",
    minWidth: "800px",
  },

  footer: {
    bottom: 0,
    left: 0,
    width: "100%",
    lineHeight: "20px",
    textAlign: "center",
    padding: "10px 0",
    height: "40px",
    backgroundColor: "#f1f1f1",
  },

  helpCenter: {
    textAlign: "center",
    position: "fixed",
    bottom: 46,
    right: 46,
  },

  helpCenterIcon: {
    cursor: "pointer",
    color: theme.palette.common.white,
  },

  helpCenterText: {
    lineHeight: "16px",
  },
}));
